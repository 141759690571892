import React, { useState, useEffect, useRef } from 'react';
import styles from '../css/ChatMobile.module.css';
import { useParams } from 'react-router-dom';
import scriptResponse from '../data/script_response';
import Toggle from '../components/Toggle';
import Information from '../components/Information';
import NegoFailForm from '../components/NegoFailForm';
import UserResponseButtons from '../components/UserResponseButtons';
import NegoSummary from '../components/NegoSummary';
import OverlayModal from '../components/OverlayModal';
import RemainingTime from '../components/RemainingTime';
import axios from 'axios';
import { X as CloseIcon } from 'lucide-react';

const api_host = process.env.REACT_APP_API_URL;

let qtType = '';

const Message = ({ 
    content, // 메시지 내용
    isBot, // 봇인지 유저인지 체크
    isFirstBotMessage ,
}) => {
    return (
        <div className={`${styles['message']} ${isBot ? styles['message-bot'] : styles['message-user']}`}>
            {isFirstBotMessage && (
                <div className={styles["chatbot-profile"]}>
                    <img 
                        src="/img/chatbot_icon.svg" 
                        alt="Chatbot Icon" 
                        className={styles["chatbot-icon"]}
                    />
                    <span className={styles["chatbot-name"]}>KT Commerce</span>
                </div>
            )}

            <div
                className={`${styles['message-content']} ${isBot ? styles['bot'] : styles['user']}`}
            >
                {content}
            </div>
        </div>
    );
}


const ChatMobile = () => {
    const { sId } = useParams();
    const testSessionId = sId;

    const [sessionId, setSessionId] = useState(null);
    const [script, setScript] = useState('');
    const [scriptName, setScriptName] = useState('');
    const [clientResponse, setClientResponse] = useState('');
    const [introData, setIntroData] = useState(null);
    const [sentences, setSentences] = useState([]);
    const [targetPrice, setTartgetPrice] = useState(); // 재견적 시 해당 가격보다 낮게 입력받도록 하기위해 필요함

    const [userName, setUserName] = useState('');
    useEffect(() => {
        const storedName = window.localStorage.getItem('partnerCode'); // localStorage에서 값 가져오기
        if (storedName) {
            setUserName(storedName); // 값이 있으면 상태 업데이트
        }
    }, []);

    const [visitTime, setVisitTime] = useState(null); // 접속 시간을 저장할 상태

    useEffect(() => {
        const formatToCustomString = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
            const day = String(date.getDate()).padStart(2, '0');
            const hour = String(date.getHours()).padStart(2, '0');
            const minute = String(date.getMinutes()).padStart(2, '0');
            const second = String(date.getSeconds()).padStart(2, '0');
        
            return `${year}${month}${day}${hour}${minute}${second}`;
        };
        const now = new Date();
        const visitTimeString = formatToCustomString(now);
        setVisitTime(visitTimeString);
    }, []);
    
    const [currentStage, setCurrentStage] = useState('');

    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null); // 마지막 메시지 위치 참조

    const [inputType, setInputType] = useState('discount_rate');

    const [productInfo, setProductInfo] = useState({
        scenario: '',
        item_name: '',
        item_code: '',
        maker_name: '',
        brand_name: '',
        model_name: '',
        item_spec: '',
        price: 0,
        vat_yn: '',
        min_order_quantity: 0,
        quantity_unit: '',
        delivery_type: '',
        lead_time: 0,
        endTime: ''
    });

    useEffect(() => {
        getIntro(testSessionId);
    }, []);

    // 팝업창 관리
    const [isInfoOpen, setIsInfoOpen] = useState(true); // 팝업 열림 상태
    const [isFirstOpen, setIsFirstOpen] = useState(false); // 첫 열림 여부

    const openInfo = () => {
        setIsInfoOpen(true);
        if (isFirstOpen) {
            setIsFirstOpen(false); // 첫 열림 후 상태 변경
        }
    }
    const closeInfo = () => setIsInfoOpen(false);

    // 협상 단계 관리 (useState 비동기 문제 해결)
    useEffect(() => {
        if (qtType && scriptName && scriptResponse[qtType] && scriptResponse[qtType][scriptName]) {
            const stage = scriptResponse[qtType][scriptName].stage;
            setCurrentStage(stage); // currentStage 업데이트
        }
    }, [qtType, scriptName, scriptResponse]);

    const stages = [
        { name: "서비스안내"},
        { name: "담당자확인"},
        { name: "협상품목안내"},
        { name: "가격협상"},
        { name: "협상종료"},
    ];

    const [showFinalMessage, setShowFinalMessage] = useState(false);
    const finalMessageArray = ["귀사가 제출해주신 의견 감사합니다.", "지금까지 KT커머스 Nego-Wiz를 통해 협상에 참여해주셔서 감사합니다."];

    // 제출 완료 후 메시지 추가
    const handleFormSubmitComplete = () => {
        setShowFinalMessage(true);
        setCurrentButtons(['협상이 종료되었습니다.']);
    };

    const getIntro = async (sessionId) => {
        // [Main] 인트로 데이터 받아오기 및 인트로 메시지 출력 함수
        // console.log('getIntro | sessionId:', sessionId);
        try {
            const response = await fetch(`${api_host}/chat/intro`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ session_id: sessionId }),
            });

            if (response.ok) {
                const data = await response.json();
                // console.log('data:', data);

                // 세션 상태 및 기타 정보 업데이트
                setSessionId(data.state.session_id);
                setScript(data.state.message.script);
                setScriptName(data.state.message.script_name);
                setClientResponse(data.state.message.client_response);

                setIntroData(data.intro_data);
                // console.log('intro_data:', data.intro_data);

                updateProductInfo({
                    scenario: data.intro_data.quotation_info.scenario, // 추후 삭제
                    image_url: data.intro_data.item_info.image_url, // 실제 이미지 데이터 추가 필요
                    item_name: data.intro_data.item_info.item_name,
                    item_code: data.intro_data.item_info.item_code,
                    maker_name: data.intro_data.item_info.maker_name,
                    brand_name: data.intro_data.item_info.brand_name, // 추후 삭제
                    model_name: data.intro_data.item_info.model_name,
                    item_spec: data.intro_data.item_info.item_spec,
                    price: data.intro_data.item_info.purchase_price,
                    vat_yn: data.intro_data.item_info.vat_yn,
                    min_order_quantity: data.intro_data.item_info.moq,
                    quantity_unit: data.intro_data.item_info.quantity_unit,
                    delivery_type: data.intro_data.item_info.delivery_type,
                    lead_time: data.intro_data.item_info.lead_time,
                    end_time: data.intro_data.quotation_info.qt_end_time,
                });

                qtType = data.intro_data.quotation_info.qt_type;
                console.log('qt_type:', qtType);

                if (qtType === '재견적') {
                    try {
                        const response = await axios.post(`${api_host}/chat/re-estimate-intro`, {
                            "qt_id": data.intro_data.quotation_info.qt_id,
                            "delay_time_1": 60,
                            "delay_time_2": 60
                        });
        
                        console.log('response', response);
                    } catch (error) {
                        console.error('재견적 준비에 실패했습니다.:', error);
                        addBotMessage('re-estimate-intro Error', false);
                    }
                }

                await getReply(data.state.session_id, data.state.message.script_name, data.state.message.script, data.intro_data.quotation_info.qt_type);
            }
        } catch (error) {
            console.error('초기 메시지를 불러오는 데 실패했습니다:', error);
            addBotMessage('Intro Error', false);
        }
    };

    const getReply = async (sessionId, scriptName, script, clientReponse) => {
        // [Main] 채팅 응답 함수
        setIsBotTyping(true);

        try {
            const response_reply = await fetch(`${api_host}/chat/reply`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_id: sessionId,
                    script_name: scriptName,
                    client_response: clientReponse,
                    script: script,
                }),
            });

            if (response_reply.ok) {
                const reply_data = await response_reply.json();
                // console.log('reply_data:', reply_data);

                // 해당 로직은 재견적의 경우만 적용
                if (reply_data.script_name === "재견적_목표_가격_제시") {
                    setInputType('price'); // 재견적 시 가격투찰 과정에서 input란이 할인율이 아니라 가격이기 때문에 inputType을 변경해줘야 함.
                    setTartgetPrice(reply_data.state.targetSupplyPrice); // 재견적 시 목표가를 수용 후, 가격 투찰과정에서 목표가 이하로 입력받게 제한하기 위함
                }

                setScript(reply_data.script);
                setScriptName(reply_data.script_name);

                const sentenceArray = reply_data.script
                    .split(/(?<!\d)\.(?!\d)/)
                    .filter(sentence => sentence.trim() !== '');

                setSentences(sentenceArray);

                // 첫 문장 출력 시작
                displayNextSentence(sentenceArray, qtType, reply_data.script_name);
                setCurrentStage(scriptResponse[qtType][scriptName].stage);

                if (['협상_성공_예', '협상_성공_아니오'].includes(reply_data.script_name)) {
                    setIntroData((prevData) => ({
                        ...prevData,
                        state: reply_data.state,
                    }));
                }
            }
        } catch (error) {
            console.error('Error:', error);
            addUserMessage('Reply Error');
        }
    };
    
    const updateProductInfo = (info) => {
        // [Main] 상품정보 업데이트 함수
        setProductInfo({
            scenario: info.scenario, // 추후 삭제
            image_url: info.image_url,
            item_name: info.item_name,
            item_code: info.item_code,
            maker_name: info.maker_name,
            brand_name: info.brand_name, // 추후 삭제
            model_name: info.model_name,
            item_spec: info.item_spec,
            price: formatPrice(info.price),
            vat_yn: info.vat_yn,
            min_order_quantity: info.min_order_quantity,
            quantity_unit: info.quantity_unit,
            delivery_type: info.delivery_type,
            lead_time: info.lead_time,
            end_time: info.end_time,
        });
    };

    const addBotMessage = (content, buttons = null) => {
        // [Main] 챗봇 메시지 출력 함수
        setMessages(prev => [...prev, { content, isBot: true, buttons }]);
    };

    const addUserMessage = (content, type) => {
        // [Main] 유저 메시지 출력 함수
        let formattedContent = content;
        if (type === 'discount_rate') {
            formattedContent = `${content}%`;
        } else if (type === 'price') {
            formattedContent = `${content}원`;
        }

        setMessages(prev => [...prev, { content: formattedContent, isBot: false }]);
    };

    const handleSendMessageButtonClick = async (buttonText) => {
        // [Main] 유저 응답'버튼' 관리 함수
        addUserMessage(buttonText);
        await getReply(sessionId, scriptName, script, buttonText);
    };

    const handleInputSubmit = async (inputValue, type) => {
        // [Main] 유저 '값 입력' 관리 함수
        addUserMessage(inputValue, type);
        await getReply(sessionId, scriptName, script, inputValue);
    };

    // const [isMessageComplete, setIsMessageComplete] = useState(false);
    const [isBotTyping, setIsBotTyping] = useState(false);
    const displayNextSentence = async (sentences, qtType, scriptName) => {
        // [Main] 문장 메시지 및 응답버튼 매칭 함수
        const responseButtonContent = scriptResponse[qtType]?.[scriptName].buttons;
        
        for (let i = 0; i < sentences.length; i++) {
            await new Promise(resolve => setTimeout(resolve, 500)); // 0.5초 대기
            addBotMessage(sentences[i], i === sentences.length - 1 ? responseButtonContent : null);
        }
        
        setIsBotTyping(false);
    };

    const scrollToBottom = () => {
        // [Sub main] 자동으로 스크롤 아래로 맞춰주는 함수
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, showFinalMessage]);

    function formatPrice(price) {
        // [Util] 가격 1000단위마다 콤마 표시하는 함수
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }


    const [inputValue, setInputValue] = useState(''); // 입력 값을 상태로 관리
    const [errorMessage, setErrorMessage] = useState('');
    const errorRef = useRef(null); // 오류 메시지 참조
    const inputRef = useRef(null); // 유저가 가격(또는 할인율) 입력 시 자동 포커싱

    const [currentButtons, setCurrentButtons] = useState([]);

    useEffect(() => {
        // 메시지가 변경될 때마다 마지막 메시지의 버튼을 currentButtons에 설정
        if (messages.length > 0) {
            const lastMessage = messages[messages.length - 1];
            setCurrentButtons(lastMessage.buttons || []);
        }
    }, [messages]);

    const handleInputChange = (e) => {
        let value = e.target.value;
    
        if (inputType === 'discount_rate') {
            // 할인율 조건: 0 이상 100 이하, 소수점 첫째 자리까지 허용
            const regex = /^(100(\.0)?|[1-9]?\d(\.\d?)?|0(\.0?|\.?\d)?)$/;
    
            // 불필요한 0 제거 (0 자체나 0.은 유지)
            value = value.replace(/^0+(?!$|\.)/, '');
    
            if (regex.test(value) || value === '') {
                setInputValue(value);
                setErrorMessage('');
            } else {
                // 오류 메시지 처리
                if (/\.\d{2,}$/.test(value)) {
                    return;
                }
            }
        } else if (inputType === 'price') {
            // 가격 조건: 0보다 큰 정수만 허용
            const regex = /^[1-9]\d*$/;
    
            if (regex.test(value) || value === '') {
                setInputValue(value);
                setErrorMessage('');
            } 
            // else {
            //     // 오류 메시지 처리
            //     if (/[^0-9]/.test(value)) {
            //         setErrorMessage('숫자만 입력할 수 있습니다.');
            //     } else if (/^0/.test(value)) {
            //         setErrorMessage('0보다 큰 숫자만 입력할 수 있습니다.');
            //     } else {
            //         setErrorMessage('올바른 형식의 숫자를 입력해 주세요.');
            //     }
            // }
        }
    };

    useEffect(() => {
        if (errorMessage) {
            scrollToBottom();  // Chat 컴포넌트에서 전달받은 함수 호출
        }
    }, [errorMessage, scrollToBottom]);

    const handleSubmit = () => {
        const trimmedValue = inputValue.trim(); // 공백 제거된 입력값

        // 입력값이 비어 있는 경우
        if (trimmedValue === '') {
            return;
        }

        if (inputType === 'discount_rate') {
            // 유효한 값일 경우에만 전송
            handleInputSubmit(trimmedValue, inputType); // 입력된 값 전송
        }

        if (inputType === 'price') {
            // if (trimmedValue === '' || isNaN(trimmedValue) || parseFloat(trimmedValue) <= 0) {
            //     setErrorMessage('올바른 가격을 입력해주세요. (0보다 큰 숫자)');
            //     return;
            // }

            if (Number(targetPrice) < Number(trimmedValue)) {
                // console.log('targetPrice:', targetPrice);
                // console.log('trimmedValue:', trimmedValue);
                // setErrorMessage(`동의한 제시 가격(${targetPrice}원)보다 낮은 가격을 투찰해주시기 바랍니다.`);
                setErrorMessage(['동의한 제시 가격보다 낮은 가격을', '투찰해주시기 바랍니다.']);
                return;
            }
    
            const formattedPrice = formatPrice(trimmedValue);
            handleInputSubmit(formattedPrice, inputType);
        }

        setInputValue(''); // 전송 후 입력 필드 초기화
        setErrorMessage(''); // 오류 메시지 초기화
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // 기본 Enter 동작 방지 (submit 등)
            handleSubmit(); // 전송 함수 호출
        }
    };


    // 모바일 화면 높이 고정
    const setScreenSize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    useEffect(() => {
        // 컴포넌트가 마운트될 때 초기화
        setScreenSize();
    
        // 창 크기가 변경될 때마다 호출
        const handleResize = () => setScreenSize();
        window.addEventListener('resize', handleResize);
    
        // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);


    // 모바일 모달 관리
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [selectedContent, setSelectedContent] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState('');

    const openModal = (title, content, variant) => {
        setSelectedTitle(title);
        setSelectedContent(content);
        setIsModalOpen(true);
        setSelectedVariant(variant);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // errorMessage가 있을 때 모달을 자동으로 열도록 설정
    useEffect(() => {
        if (errorMessage) {
            let messageLines;

            if (typeof errorMessage === 'string') {
                // 문자열일 경우 '<br>'을 기준으로 분리
                messageLines = errorMessage.split('<br>');
            } else if (Array.isArray(errorMessage)) {
                // 배열일 경우 그대로 사용
                messageLines = errorMessage;
            } else {
                // 예상치 못한 타입이 들어오는 경우 빈 배열로 초기화
                messageLines = [];
            }

            openModal(
                '',
                <h2 style={{ textAlign: 'center' }}>
                    {messageLines.map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </h2>,
                'error'
            );
            setErrorMessage('');
        }
    }, [errorMessage]);

    // 이미지 클릭시 확대 기능
    const [isImageZoomed, setIsImageZoomed] = useState(false);

    const handleImageClick = () => {
        console.log('Image clicked!');
        setIsImageZoomed(true);
    };

    const handleCloseZoom = () => {
        setIsImageZoomed(false);
    };

    return (
        <div className={styles["chat-container"]}>
            <div className={styles["chat-wrap"]}>
                <div className={styles.msger}>
                    {/* 채팅 헤더 영역 */}
                    <header className={styles["chat-header"]}>
                        <div className={styles['header-blank']} />

                        <div className={styles['header-center']}>
                            <div className={styles.logo}>
                                <span className={styles["logo-text"]}>Nego-Wiz</span>
                                <span className={styles["beta-badge"]}>Beta</span>
                            </div>
                            <div className={styles["user-info"]}>
                                <span className={styles["user-name"]}>
                                    {productInfo.scenario}
                                </span>
                            </div>
                            <div className={styles["user-info"]}>
                                <span className={styles["user-name"]}>
                                    {userName ? userName : '사용자 이름 없음'}
                                </span>
                            </div>
                        </div>

                        <div className={styles['header-blank']} />
                    </header>

                    {/* [PC] 중앙 영역: 채팅 */}
                    <div>
                        <main className={styles["chat-area"]}>
                            {(
                                <section className={styles["remaining-time"]}>
                                    <RemainingTime endTime={productInfo.end_time} />
                                </section>
                            )}

                            {(
                                <section className={styles["chat-messages"]}>
                                    {messages.map((message, index) => (
                                        <React.Fragment key={index}>
                                            <Message
                                                key={index}
                                                content={message.content}
                                                isBot={message.isBot}
                                                isFirstBotMessage={
                                                    message.isBot && (index === 0 || !messages[index - 1]?.isBot)
                                                }
                                            />

                                            {/* 마지막 메시지 다음에 협상 관련 컴포넌트 표시 */}
                                            {index === messages.length - 1 && !isBotTyping && (
                                                <>
                                                    {(scriptName === '협상_실패' || scriptName === '재견적_목표가_달성_실패') && (
                                                        <div className={styles["price-input-form-wrapper"]}>
                                                            <NegoFailForm sessionId={sessionId} qtType={qtType} onSubmitComplete={handleFormSubmitComplete} />
                                                        </div>
                                                    )}
                                                    {showFinalMessage && (
                                                        finalMessageArray.map((finalMessage, index) => (
                                                            <Message 
                                                                key={index}
                                                                content={finalMessage} 
                                                                isBot={'bot'} 
                                                                isFirstBotMessage={
                                                                    message.isBot && (index === 0 || !messages[index - 1]?.isBot)
                                                                }
                                                            />
                                                        ))
                                                    )}
                                                    {['협상_성공_예', '협상_성공_아니오'].includes(scriptName) && (
                                                        <div className={styles["nego-summary-wrapper"]}>
                                                            <NegoSummary 
                                                                visitTime={visitTime} 
                                                                summaryData={introData} 
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    <div ref={messagesEndRef} />
                                </section>
                            )}
                        </main>
                    </div>

                    {/* 유저 입력 영역 */}
                    <div className={styles["input-container"]}>
                        {/* 버튼 영역 */}
                        <div className={styles["input-upper"]}>
                            <button className={styles['content-chip']} onClick={() =>
                                openModal('상품 정보', (
                                    <div className={styles["product-info-item"]}>
                                        <img 
                                            className={styles["product-image"]} 
                                            src={productInfo.image_url} 
                                            alt='item_img' 
                                            onClick={handleImageClick}
                                        />
                                        <div className={styles["info-block"]}>
                                            <div className={styles["info-item"]}>
                                                <span className={styles['item-label']}>모델명</span>
                                                <span className={styles['item-value']} title={productInfo.model_name}>{productInfo.model_name}</span>
                                            </div>
                                        </div>
                                        <div className={styles["info-block"]}>
                                            <div className={styles["info-item"]}>
                                                <span className={styles['item-label']}>상품코드</span>
                                                <span className={styles['item-value']} title={productInfo.item_code}>{productInfo.item_code}</span>
                                            </div>
                                        </div>
                                        <div className={styles["info-block"]}>
                                            <div className={styles["info-item"]}>
                                                <span className={styles['item-label']}>제조사</span>
                                                <span className={styles['item-value']} title={productInfo.maker_name}>{productInfo.maker_name}</span>
                                            </div>
                                        </div>
                                        <div className={styles["info-block"]}>
                                            <div className={styles["info-item"]}>
                                                <span className={styles['item-label']}>상품명</span>
                                                <span className={styles['item-value']} title={productInfo.item_name}>{productInfo.item_name}</span>
                                            </div>
                                        </div>
                                        <div className={styles["info-block"]}>
                                            <div className={styles["info-item"]}>
                                                <span className={styles['item-label']}>규격</span>
                                                <span className={styles['item-value']} title={productInfo.item_spec}>{productInfo.item_spec}</span>
                                            </div>
                                        </div>
                                        <div className={styles["info-block"]}>
                                            <div className={styles["info-item"]}>
                                                <span className={styles['item-label']}>단가</span>
                                                <span className={styles['item-value']} title={productInfo.price}>{productInfo.price}원</span>
                                            </div>
                                        </div>
                                        <div className={styles["info-block"]}>
                                            <div className={styles["info-item"]}>
                                                <span className={styles['item-label']}>VAT포함여부</span>
                                                <span className={styles['item-value']} title={productInfo.vat_yn}>{productInfo.vat_yn}</span>
                                            </div>
                                        </div>
                                        <div className={styles["info-block"]}>
                                            <div className={styles["info-item"]}>
                                                <span className={styles['item-label']}>최소주문수량</span>
                                                <span className={styles['item-value']} title={productInfo.min_order_quantity}>{productInfo.min_order_quantity} {productInfo.quantity_unit}</span>
                                            </div>
                                        </div>
                                        <div className={styles["info-block"]}>
                                            <div className={styles["info-item"]}>
                                                <span className={styles['item-label']}>리드타임</span>
                                                <span className={styles['item-value']} title={productInfo.lead_time}>{productInfo.lead_time}일</span>
                                            </div>
                                        </div>
                                        <div className={styles["info-block"]}>
                                            <div className={styles["info-item"]}>
                                                <span className={styles['item-label']}>배송형태</span>
                                                <span className={styles['item-value']} title={productInfo.delivery_type}>{productInfo.delivery_type}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }>
                                상품정보
                            </button>

                            {/* Zoomed Image Modal */}
                            {isImageZoomed && (
                                <div 
                                    className={styles["zoomed-modal"]}
                                    onClick={handleCloseZoom}
                                >
                                    <div className={styles["zoomed-image-container"]}>
                                        <img
                                            src={productInfo.image_url}
                                            alt="image-origin"
                                            className={styles["zoomed-image"]}
                                        />
                                        <button 
                                            className={styles["zoomed-close-button"]}
                                            onClick={handleCloseZoom}
                                        >
                                            <CloseIcon size={24} />
                                        </button>
                                    </div>
                                </div>
                            )}

                            <button className={styles['content-chip']} onClick={() =>
                                openModal('협상 절차', (
                                    <div className={styles["stage-container"]}>
                                        {stages.map((stage, index) => (
                                            <div
                                                key={index}
                                                className={`${styles['stage']} ${currentStage === stage.name ? styles['stage-active'] : styles['stage-inactive']}`}
                                            >
                                                {/* 번호 영역 */}
                                                <div className={`${styles['stage-number']} ${currentStage === stage.name ? styles['active'] : ''}`}>
                                                    {index + 1}
                                                </div>
                            
                                                {/* 텍스트 영역 */}
                                                <span className={`${styles['stage-text']} ${currentStage === stage.name ? styles['active-text'] : ''}`}>
                                                    {stage.name}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                ))
                            }>
                                협상 절차
                            </button>
                            
                            <button className={styles['content-chip']} onClick={() =>
                                openModal('협상 유의사항 및 서비스 이용안내', '', 'information')
                            }>
                                서비스 이용 안내
                            </button>
                            
                            <button className={styles['content-chip']} onClick={() =>
                                openModal('참여 업체 정보', (
                                    <div>
                                        <p>10개 이상 20개 미만의 업체가 협상에 참여중입니다.</p>
                                    </div>
                                ))
                            }>
                                참여 업체 정보
                            </button>
                            
                            <button className={styles['content-chip']} onClick={() =>
                                openModal('이전 협상 정보', (
                                    <div>
                                        <p>이전 협상 정보</p>
                                    </div>
                                ))
                            }>
                                이전 협상 정보
                            </button>
                        </div>
                        
                        {/* 모달 컴포넌트 */}
                        <OverlayModal
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            title={selectedTitle}
                            content={selectedContent}
                            variant={selectedVariant}
                        />
                        
                        {/* 유저 입력 영역 */}
                        <div className={styles["input-lower"]}>
                            <UserResponseButtons 
                                buttons={currentButtons}
                                inputType={inputType}
                                errorMessage={errorMessage}
                                inputValue={inputValue}
                                handleInputChange={handleInputChange}
                                handleKeyDown={handleKeyDown}
                                inputRef={inputRef}
                                handleSubmit={handleSubmit}
                                onButtonClick={handleSendMessageButtonClick}
                                scriptName={scriptName}
                                visitTime={visitTime}
                                introData={introData}
                                errorRef={errorRef}
                                isBotTyping={isBotTyping}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatMobile;