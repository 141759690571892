import { useState, useEffect } from 'react';

// 해당 함수로 화면 크기에 따른 상태를 확인합니다.
const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
        setMatches(media.matches);
        }

        const listener = () => setMatches(media.matches);
        media.addEventListener('change', listener);

        return () => media.removeEventListener('change', listener);
    }, [query, matches]);

    return matches;
};

export default useMediaQuery;
