import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, Columns } from 'lucide-react';

const Toggle = ({ title, children, openCondition = false }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    useEffect(() => {
        // openCondition이 true일 때만 열림, 한번 열리면 계속 유지됨
        if (openCondition && !isOpen) {
            setIsOpen(true);
        }
    }, [openCondition]);

    // 인라인 스타일 정의
    const containerStyle = {
        // border: '1px solid #e2e8f0',
        borderRadius: '8px',
        overflow: 'hidden',
        marginBottom: '15px'
    };

    const buttonStyle = {
        width: '100%',
        padding: '10px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#F7F8FA',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        outline: 'none', // 포커스 시 테두리 제거
        border: 'none',  // 기본 테두리 제거
    };

    const buttonHoverStyle = {
        backgroundColor: '#F7F8FA',
    };

    const contentStyle = {
        maxHeight: isOpen ? '1200px' : '0',
        opacity: isOpen ? 1 : 0,
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out',
    };

    const innerContentStyle = {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        padding: '12px',
        backgroundColor: '#F7F8FA',
    };

    return (
        <div style={containerStyle}>
            <button
                style={{ ...buttonStyle, ...(isOpen ? buttonHoverStyle : {}) }}
                onClick={toggleOpen}
            >
                <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{title}</span>
                {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
            <div style={contentStyle}>
                <div style={innerContentStyle}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Toggle;
