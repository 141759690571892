import React, { useRef, useState, useEffect } from 'react';
import '../css/NegoSummary.css';


const NegoSummary = ({ visitTime, summaryData }) => {
    // console.log('summaryData:', summaryData);
    // console.log('visitTime:', visitTime);
    const qt_start_time = formatDate(visitTime);
    const email_delivery_time = formatDate(summaryData.quotation_info.qt_start_time);
    const qt_end_time = formatDate(getCurrentDateString());
    const md_name = getLastName(summaryData.quotation_info.md_name);
    const priceToKorean= numberToKorean(summaryData.state.targetSupplyPrice);
    const nextQtDate = formatDate(getOneYearLaterDate(getCurrentDateString()), false);

    return (
        <div className="negotiation-summary">
            <div className="summary-header">
                협상 결과 요약
            </div>
            <p className="summary-intro">
                협상이 완료되어 아래와 같이 결과를 요약하오니 다시 한 번 최종 확인 부탁 드립니다.<br/>
                최종 확인 후 협상 결과에 대한 수정 변경은 불가함을 안내 드립니다.
            </p>

            <ul className="summary-list">
                <li>협상 개시 시간: {qt_start_time} (이메일 발송: {email_delivery_time})</li>
                <li>협상 종료 시간: {qt_end_time}</li> {/* 협상이 종료된 시점의 시간 */}
                <li>
                    낙찰 대상자: {summaryData.supplier_info.supplier_name}, {summaryData.supplier_info.manager_name} ({summaryData.supplier_info.manager_phone}) {summaryData.supplier_info.manager_email}
                </li>
                <li>최종 협상 회차: {summaryData.quotation_info.qt_seq + 1}회차 협상 종료</li>
                <li>협상 상세 내역</li>
            </ul>

            <div className="product-table">
                <div className="table-row">
                    <div><strong>상품명</strong></div>
                    <div>{summaryData.item_info.item_name}</div>
                    {/* <div>모델명</div>
                    <div>{summaryData.item_info.model_name}</div> */}
                    <div><strong>최소 주문</strong></div>
                    <div>{summaryData.item_info.moq} {summaryData.item_info.quantity_unit}</div>
                </div>
                <div className="table-row">
                    <div><strong>상품 코드</strong></div>
                    <div>{summaryData.item_info.item_code}</div>
                    <div><strong>제조사</strong></div>
                    <div>{summaryData.item_info.maker_name}</div>
                </div>
                <div className="table-row">
                    <div><strong>제품 규격</strong></div>
                    <div>{summaryData.item_info.item_spec}</div>
                    <div><strong>제조 국가</strong></div>
                    <div>{summaryData.item_info.origin}</div>
                </div>
                <div className="table-row">
                    <div><strong>배송 조건</strong></div>
                    <div>{summaryData.item_info.delivery_type}</div>
                    <div><strong>배송 리드타임</strong></div>
                    <div>{summaryData.item_info.lead_time}</div>
                </div>
            </div>

            <ul className="summary-list">
                <li>최종 협의 가격: {formatPrice(summaryData.state.targetSupplyPrice)}원 ({priceToKorean}원) VAT별도</li>
                <li>공급 계약 기간: 협상 완료일로부터 1년 ({nextQtDate})까지</li>
                {/* <li>공급 계약 기간: 협상 완료일로부터 1년 (aa)까지</li> */}
                <li>
                    담당 MD: {md_name} ({summaryData.quotation_info.md_contact_number}) {summaryData.quotation_info.md_email}
                </li>
            </ul>

            <p className="summary-footer">
                상기 내용에 이상이 없으며 최종 협상 결과에 동의하여 이를 승인합니다.
            </p>
        </div>
    );
};

export default NegoSummary;



const digits = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
const units = ['', '십', '백', '천'];
const higherUnits = ['', '만', '억', '조', '경', '해'];

function processGroup(num, isFirstGroup = false) {
    const numStr = num.toString().padStart(4, '0');
    let result = '';

    for (let idx = 0; idx < numStr.length; idx++) {
        const digit = parseInt(numStr[idx]);
        const unitIdx = 3 - idx; // 천, 백, 십, 일 순서
        const unit = units[unitIdx];

        if (digit === 0) continue;

        if (digit === 1) {
            if (unit !== '') {
                if (isFirstGroup && unit === '천') {
                    result += digits[digit] + unit; // 맨 앞자리의 '일천' 처리
                } else {
                    result += unit; // '십', '백' 등에서는 '일' 생략
                }
            } else {
                result += '일';
            }
        } else {
            result += digits[digit] + unit;
        }
    }
    return result;
}

const getLastName = (input) => {
    const parts = input.split('_'); // '_' 기준으로 분할
    return parts[parts.length - 1]; // 마지막 요소 반환
};

const formatDate = (dateString, showTime = true) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const hour = dateString.substring(8, 10);
    const minute = dateString.substring(10, 12);

    if (showTime) {
        return `${year}년 ${parseInt(month)}월 ${parseInt(day)}일 ${hour}시 ${minute}분`;
    } else {
        return `${year}년 ${parseInt(month)}월 ${parseInt(day)}일`;
    }
};

const getCurrentDateString = () => {
    const now = new Date(); // 현재 시간 가져오기

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');

    // 'YYYYMMDDHHmm' 형식의 문자열 반환
    return `${year}${month}${day}${hour}${minute}`;
};

const numberToKorean = (num) => {
    if (num === 0) return '영';

    const groups = [];
    let numStr = num.toString();

    while (numStr.length > 0) {
        groups.unshift(parseInt(numStr.slice(-4)));
        numStr = numStr.slice(0, -4);
    }

    let result = '';
    const groupLen = groups.length;

    for (let idx = 0; idx < groupLen; idx++) {
        const group = groups[idx];
        if (group === 0) continue;

        const isFirstGroup = (idx === 0);
        const groupStr = processGroup(group, isFirstGroup);
        const higherUnit = higherUnits[groupLen - idx - 1];

        result += groupStr + higherUnit;
    }

    return result;
}

const getOneYearLaterDate = (dateString) => {
    const year = parseInt(dateString.substring(0, 4));
    const month = parseInt(dateString.substring(4, 6)) - 1;
    const day = parseInt(dateString.substring(6, 8));
    const hour = parseInt(dateString.substring(8, 10));
    const minute = parseInt(dateString.substring(10, 12));

    const originalDate = new Date(year, month, day, hour, minute);
    originalDate.setFullYear(originalDate.getFullYear() + 1);

    const newYear = originalDate.getFullYear();
    const newMonth = String(originalDate.getMonth() + 1).padStart(2, '0');
    const newDay = String(originalDate.getDate()).padStart(2, '0');

    return `${newYear}${newMonth}${newDay}0000`; // 시간은 '00:00'으로 설정
};

const formatPrice = (price) => {
    // [Util] 가격 1000단위마다 콤마 표시하는 함수
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}