import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Welcome.css'
import '../css/Common.css';

// 협력사에게 발송하는 챗봇 참여 URL의 엔드포인트는 '협력사 코드'임
// (주)오투오의 협력사 코드가 12345라고 하면, 'https://ktc-ai-nego.o2o.kr/12345'로 메일을 발송함.
// 이 URL에서 협력사 코드인 12345를 파싱해서 리스트 페이지에서 견적 리스트를 보여줄 때 API 파라미터로 요청해야함.
// [GET] 

const Welcome = () => {
    const [partnerCode, setPartnerCode] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Parse partner code from URL
        const url = new URL(window.location.href);
        console.log('url:', url);
        const pathSegments = url.pathname.split('/').filter(segment => segment !== '');
        const code = pathSegments[pathSegments.length - 1];
        
        console.log('Extracted code:', code);
        setPartnerCode(code);

        // Store partner code in localStorage
        localStorage.setItem('partnerCode', code);

        // Log the stored partner code to the console
        console.log('Stored partner code:', localStorage.getItem('partnerCode'));
    }, []);

    const handleConfirm = () => {
        navigate('/login');
    };

    const steps = [
        '서비스안내',
        '담당자확인',
        '협상품목안내',
        '가격협상',
        '협상종료'
    ];

    return (
        <div className="container">

            <h1 className="title">KT <span className='p-red'>commerce</span> Nego-Wiz</h1>
            {/* {partnerCode && (
                <p className="partner-code">파트너 코드: {partnerCode}</p>
            )} */}
            <div className="card mb-6">
                {/* <div className="card-header">
                    <h2 className="subtitle">협상 전 안내</h2>
                </div> */}
                <div className="card-content">
                    <p className="mb-4">안내사항</p>
                    <p className='mb-10'>본 서비스는 현재 Beta 서비스 중으로, 귀사의 참여도 및 상호 반응을 데이터화 할 예정이며, 이는 향후 더 많은 협상 기회를 제공해 드리기 위함입니다.</p>
                </div>
            </div>
            <div className="card">
                {/* <div className="card-header">
                    <h3 className="subtitle">협상 절차</h3>
                </div> */}
                <div className="card-content">
                    <p className="mb-4">협상절차</p>
                    <p className="mb-10">아래와 같이 협상이 진행됩니다.</p>
                    <ol className="process-list">
                        {steps.map((step, index) => (
                            <li key={index} className="process-item mb-10">{step}</li>
                        ))}
                    </ol>
                </div>
            </div>
            <div className="button-container">
                <button className="button" onClick={handleConfirm}>협상 참여</button>
            </div>
        </div>
    );
};

export default Welcome;