import React, { useEffect, useState } from 'react';
import styles from '../css/OverlayModal.module.css';
import Information from './Information';

const OverlayModal = ({ isOpen, onClose, title, content, variant }) => {
    const [closing, setClosing] = useState(false);

    const handleClose = () => {
        setClosing(true); // 닫힐 때 애니메이션 적용
        setTimeout(() => {
            setClosing(false); // 상태 초기화
            onClose(); // 부모 컴포넌트에 닫힘 이벤트 전달
        }, 300); // 닫힘 애니메이션 시간 (0.3s)과 일치하게 설정
    };

    useEffect(() => {
        if (isOpen) setClosing(false); // 열릴 때 상태 초기화
    }, [isOpen]);

    if (!isOpen && !closing) return null;

    return (
        <div className={styles.overlay} onClick={handleClose}>
            <div
                className={`${styles.modal} ${closing ? styles.slideDown : ''}`}
                onClick={(e) => e.stopPropagation()}
            >
                {variant === 'error' ? (
                    // 오류 메시지 스타일
                    <div className={styles.errorContent}>
                        <div>{content}</div>
                        <button className={styles.confirmButton} onClick={handleClose}>
                            확인
                        </button>
                    </div>
                ) : variant === 'information' ? (
                    <>
                        <div className={styles.modalHeader}>
                            <h2 style={{ margin: 0 }}>{title}</h2>
                            <button className={styles.closeButton} onClick={handleClose}>
                                ×
                            </button>
                        </div>
                        <Information onClose={handleClose} showTitle={false} />
                    </>
                ) : (
                    // 기본 스타일
                    <>
                        <div className={styles.modalHeader}>
                            <h2 style={{ margin: 0 }}>{title}</h2>
                            <button className={styles.closeButton} onClick={handleClose}>
                                ×
                            </button>
                        </div>
                        <div className={styles.modalContent}>{content}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default OverlayModal;
