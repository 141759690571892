import React, { useEffect, useState } from 'react';
import styles from '../css/RemainingTime.module.css';

const RemainingTime = ({ endTime }) => {
    const [timeLeft, setTimeLeft] = useState('00시간 00분 00초');
    const [isLessThan24Hours, setIsLessThan24Hours] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            const remainingTime = calculateTimeLeft();
            setTimeLeft(remainingTime.time);
            setIsLessThan24Hours(remainingTime.isLessThan24Hours);
        }, 1000);

        return () => clearInterval(timer);
    }, [endTime]);

    // 종료까지 남은 시간 계산 함수
    function calculateTimeLeft() {
        const now = new Date();
        const end = parseEndTime(endTime); // 문자열을 Date 객체로 변환
        const difference = end - now;

        if (difference <= 0) {
            return { time: "00시간 00분 00초", isLessThan24Hours: false };
        }

        const hours = String(Math.floor(difference / (1000 * 60 * 60))).padStart(2, '0');
        const minutes = String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
        const seconds = String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, '0');

        const isLessThan24 = difference < 24 * 60 * 60 * 1000;

        return { time: `${hours}시간 ${minutes}분 ${seconds}초`, isLessThan24Hours: isLessThan24 };
    }

    // 문자열을 Date 객체로 변환하는 함수
    function parseEndTime(endTimeString) {
        const year = parseInt(endTimeString.slice(0, 4), 10);
        const month = parseInt(endTimeString.slice(4, 6), 10) - 1; // JavaScript의 월은 0부터 시작
        const day = parseInt(endTimeString.slice(6, 8), 10);
        const hours = parseInt(endTimeString.slice(8, 10), 10);
        const minutes = parseInt(endTimeString.slice(10, 12), 10);
        const seconds = parseInt(endTimeString.slice(12, 14), 10);

        return new Date(year, month, day, hours, minutes, seconds);
    }

    return (
        <div className={styles.container}>
            {/* <span className={styles.icon}>⏰</span> */}
            <img 
                src='/img/remain_time_icon.svg' 
                alt='remaining-time'
                className={styles.timeIcon}
            />
            <span className={`${styles.timeText} ${isLessThan24Hours ? styles.warning : ''}`}>
                입찰 잔여 시간: {timeLeft}
            </span>
        </div>
    );
};

export default RemainingTime;
