// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RemainingTime_container__jN9An {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #606060;
    color: #ffffff;
    padding: 15px 20px;
    border-radius: 25px;
    width: fit-content;
}

.RemainingTime_timeIcon__ggeQI {
    margin-right: 10px;
}

.RemainingTime_timeText__okIRg {
    font-size: 16px;
    font-weight: 400;
}

/* 24시간 미만일 때 텍스트 색상을 빨간색으로 변경 */
.RemainingTime_warning__AaBmg {
    color: #ff4d4d;
}

/* @media only screen and (max-width: 768px) {
    .container {
        width: 66.4vw;
    }
} */
`, "",{"version":3,"sources":["webpack://./src/css/RemainingTime.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA,gCAAgC;AAChC;IACI,cAAc;AAClB;;AAEA;;;;GAIG","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #606060;\n    color: #ffffff;\n    padding: 15px 20px;\n    border-radius: 25px;\n    width: fit-content;\n}\n\n.timeIcon {\n    margin-right: 10px;\n}\n\n.timeText {\n    font-size: 16px;\n    font-weight: 400;\n}\n\n/* 24시간 미만일 때 텍스트 색상을 빨간색으로 변경 */\n.warning {\n    color: #ff4d4d;\n}\n\n/* @media only screen and (max-width: 768px) {\n    .container {\n        width: 66.4vw;\n    }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RemainingTime_container__jN9An`,
	"timeIcon": `RemainingTime_timeIcon__ggeQI`,
	"timeText": `RemainingTime_timeText__okIRg`,
	"warning": `RemainingTime_warning__AaBmg`
};
export default ___CSS_LOADER_EXPORT___;
