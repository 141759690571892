import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Welcome from './pages/Welcome';
import QuotationList from './pages/QuotationList';
import Login from './pages/Login';
import Chat from './pages/Chat';
import ChatMobile from './pages/ChatMobile';
// import './App.css';
import './index.css';
import useMediaQuery from './hooks/useMediaQuery';

function App() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Welcome />} /> */}
          <Route path="/:partnerCode" element={<Welcome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/list" element={<QuotationList />} />
          <Route 
            path="/chat/:sId" 
            element={isMobile ? <ChatMobile /> : <Chat />} 
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
