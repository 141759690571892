// components/UserResponseButtons.js
import React, { useState, useEffect, useRef } from 'react';
import '../css/UserResponseButtons.css';
import TypingIndicator from './TypingIndicator';

const UserResponseButtons = ({ 
    buttons, 
    inputType,
    errorMessage,
    inputValue,
    handleInputChange,
    handleKeyDown,
    inputRef,
    handleSubmit,
    onButtonClick,
    errorRef,
    isBotTyping,
}) => {
    if (!isBotTyping && buttons[0] === 'end') return null;
    
    return (
        <div className="fixed-bottom-buttons">
            <div className="user-button-container">
                {isBotTyping ? (
                    <TypingIndicator />
                ) : buttons[0] === 'input' ? (
                    <div className="input-container">
                        <label className="input-label">
                            {inputType === 'discount_rate' ? '할인율(%)을 입력해주세요' : '가격(원)을 입력해주세요'}
                            {errorMessage && <div ref={errorRef} className="error-message">{errorMessage}</div>}
                        </label>
                        <div className="input-wrapper">
                            <input
                                // type="text"
                                type="number"
                                inputMode="decimal"
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                className={`input-field ${errorMessage ? 'error' : ''}`}
                                placeholder={inputType === 'discount_rate' ? '할인율(%) 소수점 첫째 자리까지' : '가격 투찰(숫자만 입력)'}
                                ref={inputRef}
                                data-type={inputType}
                            />
                            <div className="unit" data-before={inputValue}>
                                {inputValue && (
                                    inputType === 'discount_rate' ? "%" : "원"
                                )}
                            </div>
                            <button
                                onClick={handleSubmit}
                                className="input-button"
                                disabled={!inputValue}
                            >
                                전송
                            </button>
                        </div>
                    </div>
                ) : ( 
                    <div className="user-button-item">
                        {buttons.map((button, index) => (
                            <button
                                key={index}
                                onClick={() => onButtonClick(button)}
                                className={`user-button ${button === "아니오" ? "user-button-no" : ""}`}
                                disabled={["협상이 종료되었습니다.", "최종 제안 단가를 입력해주세요.", "end"].includes(button)}
                            >
                                {button}
                            </button>
                        ))}
                    </div> 
                )}
            </div>
        </div>
    )
};
export default UserResponseButtons;
