// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    background: #FFF;
  }
.logo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.logo-text {
    font-size: 24px;
    font-weight: bold;
}

.beta-badge {
    font-size: 14px;
    color: #00b8bf;
    background-color: #e0f7fa;
    padding: 2px 8px;
    border-radius: 8px;
}

.user-code {
    font-size: 18px;
    font-weight: bold;
}

.chat-headers {
    display: flex;
    height: 54px;
    border-bottom: 1px solid #e0e0e0;
    background-color: white;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

@media (min-width: 1024px) {
    .header-blank {
        width: 18%;
        padding: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/Common.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;EAClB;AACF;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,gCAAgC;IAChC,uBAAuB;IACvB,8CAA8C;AAClD;;AAEA;IACI;QACI,UAAU;QACV,aAAa;IACjB;AACJ","sourcesContent":[".header-center {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    flex-grow: 1;\n    background: #FFF;\n  }\n.logo {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.logo-text {\n    font-size: 24px;\n    font-weight: bold;\n}\n\n.beta-badge {\n    font-size: 14px;\n    color: #00b8bf;\n    background-color: #e0f7fa;\n    padding: 2px 8px;\n    border-radius: 8px;\n}\n\n.user-code {\n    font-size: 18px;\n    font-weight: bold;\n}\n\n.chat-headers {\n    display: flex;\n    height: 54px;\n    border-bottom: 1px solid #e0e0e0;\n    background-color: white;\n    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */\n}\n\n@media (min-width: 1024px) {\n    .header-blank {\n        width: 18%;\n        padding: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
