import axios from 'axios';
import React, { useState } from 'react';
import '../css/NegoFailForm.css';


const api_host = process.env.REACT_APP_API_URL;

const NegoFailForm = ({ sessionId, qtType, onSubmitComplete }) => {
    const [price, setPrice] = useState(0);
    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [isSubmited, setIsSubmited] = useState(false); // 제출여부 상태
    const [submitMessage, setSubmitMessage] = useState(''); // 제출 후 표시할 메시지
    const [errorMessage, setErrorMessage] = useState(''); // 에러메시지 상태

    const handlePriceChange = (e) => {
        // console.log('price_change:', e.target.value);
        let value = e.target.value;
    
        // 가격 조건: 0보다 큰 정수만 허용
        const regex = /^[1-9]\d*$/;

        if (regex.test(value) || value === '') {
            setPrice(value);
            setErrorMessage('');
        }

        // setPrice(e.target.value);
    };

    const handleReasonChange = (e) => {
        // console.log('reason_change:', e.target.value);
        setReason(e.target.value);
        setErrorMessage(''); // 오류 메시지 초기화
    };

    const handleOtherReasonChange = (e) => {
        // console.log('other_reason_change:', e.target.value);
        setOtherReason(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // 입력한 가격에서 선행 0을 제거
        const normalizedPrice = String(Number(price));

        // 유효성 검사
        if (!normalizedPrice || Number(normalizedPrice) <= 0) {
            setErrorMessage('유효한 가격을 입력해주세요.');
            return;
        }

        let finalReason = reason;

        if (qtType === '재협상') {
            if (!reason) {
                setErrorMessage('사유를 선택해주세요.');
                return;
            }

            if (reason === '기타' && otherReason.trim() === '') {
                setErrorMessage('기타 사유를 입력해주세요.');
                return;
            }
        } else {
            finalReason = '재견적';
        }

        // 초기화 및 폼 제출
        setPrice(0);
        setReason('');
        setOtherReason('');
        setErrorMessage('');
        
        try {
            const negoFailFormData = new FormData();
            negoFailFormData.append('session_id', sessionId);
            negoFailFormData.append('proposed_SupplyPrice', Math.floor(Number(normalizedPrice)));
            negoFailFormData.append('rejection_reason', rejectionReason[finalReason]);

            const response = await axios.post(`${api_host}/chat/nego-fail`, negoFailFormData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            console.log('response', response);
            
            setSubmitMessage({
                success: '제출에 성공했습니다.',
                priceMessage: `제출 가격: ${Number(normalizedPrice).toLocaleString()}원`,
            });
            setIsSubmited(true);
        } catch (error) {
            setErrorMessage('제출 중 오류가 발생했습니다. 다시 시도해 주세요.');
            setIsSubmited(false);
        }
        
        onSubmitComplete(); // 상위 컴포넌트에 콜백 호출
    };

    const rejectionReason = {
        "가격인상": "'원재료 가격 상승' 또는 '제조사 가격 인상'으로 요청한 공급가격을 맞출 수 없습니다.",
        "수량": "주문 수량이 적어, 소량 생산 시 발생하는 제조비용으로 맞출 수 없습니다.",
        "단종": "현재 단종된 제품으로 물량 수급이 원활하지 않아 가격을 맞출 수 없습니다.",
        "품절": "해당 상품이 품절되어 납품할 수 없습니다.",
        "기타": otherReason,
        "재견적": "재견적 가격제안 거절"   
    }

    return (
        <form 
            onSubmit={handleSubmit} 
            className='price-input-form'
        >
            <div className="price-input-header">
                협상 합의 불가 사유 및 최종 제안 단가 입력
            </div>
            <p className='price-input-intro'>
                협상이 합의에 도달하지 못하였습니다. 최종 희망하는 가격을 입력하여 주시기 바랍니다.
            </p>
        
            <div className='price-section'>
                <label style={{ marginRight: '10px' }}>공급 희망 가격</label>
                <input
                    className='fail-price'
                    type="number"
                    min="0"
                    value={price}
                    onChange={handlePriceChange}
                    style={{ 
                        padding: '5px',
                        width: '150px',
                        marginRight: '10px'
                    }}
                />
                <span>원 (VAT 별도)</span>
            </div>

            {qtType === '재협상' && (
                <>
                    <p className='price-input-intro'>
                        추가로 제시한 가격을 수용할 수 없는 이유를 선택하여 주시기 바라며, 목록에 없는 경우 기타 항목 선택 후 간단한 사유를 기재하여 주시기 바랍니다.
                    </p>

                    <div className='reason-section'>
                        {['가격인상', '수량', '단종', '품절', '기타'].map((option) => (
                        <div className='reason' key={option}>
                            <label>
                            <input
                                type="radio"
                                value={option}
                                checked={reason === option}
                                onChange={handleReasonChange}
                                required
                            />
                            {option === '가격인상' && rejectionReason['가격인상']}
                            {option === '수량' && rejectionReason['수량']}
                            {option === '단종' && rejectionReason['단종']}
                            {option === '품절' && rejectionReason['품절']}
                            {option === '기타' && (
                                <>
                                    기타
                                    <input
                                        className='etc-reason-input'
                                        type="text"
                                        value={otherReason}
                                        onChange={handleOtherReasonChange}
                                        placeholder="제시한 가격을 수용할 수 없는 이유를 작성해주세요."
                                        required={reason === '기타'}
                                    />
                                </>
                            )}
                            </label>
                        </div>
                        ))}
                    </div>
                </>
            )}
            
            <div className='submit-section'>
                {errorMessage && (
                    <p style={{ margin: '10px 0 0 0', color: 'red' }}>{errorMessage}</p>
                )}
                {!isSubmited && (
                    <button 
                        className='submit-btn' 
                        type="submit"
                        disabled={
                            !price || (qtType === '재협상' && !reason)
                        }
                    >
                        제출
                    </button>
                )}
            </div>

            {submitMessage && (
                <div style={{ textAlign: 'center', color: 'green' }}>
                    <p>{submitMessage.success}</p>
                    <p>{submitMessage.priceMessage}</p>
                </div>
            )}
        </form>
    );
};

export default NegoFailForm;