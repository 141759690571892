import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Login.css';
import '../css/Common.css';

const api_host = process.env.REACT_APP_API_URL;

const Login = () => {
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    
    useEffect(() => {
        // Log the stored partner code to the console
        let partnerCode = localStorage.getItem('partnerCode');
        console.log('Stored partner code:', partnerCode);
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${api_host}/auth/verify-code`, { 
                user_id: id,
                user_pw: password,
                supplier_code: '29116'
            });
            if (response.status === 200) {
                // 로그인 성공
                console.log('로그인 성공');
                navigate('/list'); // 로그인 성공 후 리스트 페이지로 이동
            }
        } catch (error) {
            console.error('로그인 실패:', error);
            alert('로그인에 실패했습니다. 아이디와 비밀번호를 확인해주세요.');
        }
    };

    return (
        
        <div className="login-container">


            <div className="login-header">
                <h1>Nego-Wiz <span className="beta-badge">Beta</span></h1>
            </div>
    
            <h2 className="login-title">로그인</h2>
                <h3 className="login-subtitle">통합플랫폼 계정 정보를 입력해주세요</h3>
            <form onSubmit={handleLogin} className="login-form">
                <input
                    type="text"
                    placeholder="아이디를 입력해주세요"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    className="login-input"
                />
                <input
                    type="password"
                    placeholder="비밀번호를 입력해주세요"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="login-input"
                />
                <button type="submit" className="login-button">확인</button>
            </form>
        </div>
    );
};

export default Login;