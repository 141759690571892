// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typing-indicator {
    background-color: #e6e6e6;
    will-change: transform;
    width: auto;
    border-radius: 50px;
    padding: 10px;
    display: table;
    margin: 0 auto;
    position: relative;
    animation: 2s bulge infinite ease-out;
}

.typing-indicator span {
    height: 6px;
    width: 6px;
    float: left;
    margin: 0 1px;
    background-color: #9e9ea1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
    animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
    animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
    animation: 1s blink infinite 0.9999s;
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}

@keyframes bulge {
    50% {
        transform: scale(1.05);
    }
}`, "",{"version":3,"sources":["webpack://./src/css/TypingIndicator.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,qCAAqC;AACzC;;AAEA;IACI,WAAW;IACX,UAAU;IACV,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".typing-indicator {\n    background-color: #e6e6e6;\n    will-change: transform;\n    width: auto;\n    border-radius: 50px;\n    padding: 10px;\n    display: table;\n    margin: 0 auto;\n    position: relative;\n    animation: 2s bulge infinite ease-out;\n}\n\n.typing-indicator span {\n    height: 6px;\n    width: 6px;\n    float: left;\n    margin: 0 1px;\n    background-color: #9e9ea1;\n    display: block;\n    border-radius: 50%;\n    opacity: 0.4;\n}\n\n.typing-indicator span:nth-of-type(1) {\n    animation: 1s blink infinite 0.3333s;\n}\n\n.typing-indicator span:nth-of-type(2) {\n    animation: 1s blink infinite 0.6666s;\n}\n\n.typing-indicator span:nth-of-type(3) {\n    animation: 1s blink infinite 0.9999s;\n}\n\n@keyframes blink {\n    50% {\n        opacity: 1;\n    }\n}\n\n@keyframes bulge {\n    50% {\n        transform: scale(1.05);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
