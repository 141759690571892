import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/QuotationList.css';
import '../css/Common.css';

const QuotationList = () => {
    const [products, setProducts] = useState([]);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const navigate = useNavigate();
    const [partnerCode, setPartnerCode] = useState('');

    useEffect(() => {
      const storedPartnerCode = localStorage.getItem('partnerCode');
      console.log('Stored partner code:', storedPartnerCode);
      setPartnerCode(storedPartnerCode || '');
  }, []);
    
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                // const response = await axios.get('https://ktc.product/list?supplier_id=T29116');
                const response = await axios.get('https://kt-commerce-nego-api.o2o.kr/product/list?supplier_id=T29116');
                
                if (response.data.condition === 'success') {
                    setProducts(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const handleProductSelect = (qtId) => {
        setSelectedProductId(qtId);
    };

    const handleNegotiate = () => {
        if (selectedProductId) {
            const selectedProduct = products.find(p => p.qt_id === selectedProductId);
            if (selectedProduct) {
                navigate(`/chat/${selectedProduct.session_id}`);
            }
        } else {
            alert('상품을 선택해주세요.');
        }
    };

    const formatDateTime = (dateTimeString) => {
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        return `${year}-${month}-${day} ${hour}:${minute}`;
    };

    return (
        <div className="quotation-list-container">
            {/* <header className="app-header">
                <h1 className="app-title">Nego-Wiz</h1>
                <span className="app-beta">Beta</span>
                <button className="header-button">협력사 이용</button>
            </header> */}
            <div className='chat-headers'>
                <div className='header-blank'></div>

                <div className="header-center">
                <div className="logo">
                    <span className="logo-text">Nego-Wiz</span>
                    <span className="beta-badge">Beta</span>
                </div>
                <div className="user-info">
                    <span className="user-code">{partnerCode}</span>
                </div>
                </div>

                <div className='header-blank'></div>
            </div>

            <h2 className="page-title">상품 선택</h2>
            <div className="table-container">
                <table className="product-table-list">
                    <thead>
                        <tr>
                            <th>상태</th>
                            <th>상품명</th>
                            <th>모델명</th>
                            <th>제조사</th>
                            <th>시나리오</th>
                            <th>견적번호</th>
                            <th>구분</th>
                            <th>마감일</th>
                            <th>차수</th>
                            <th>상품코드</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product) => (
                            <tr 
                                key={product.qt_id}
                                onClick={() => handleProductSelect(product.qt_id)}
                                className={selectedProductId === product.qt_id ? 'selected' : ''}
                            >
                                <td className={`status ${product.qt_status}`}>{product.qt_status}</td>
                                <td>{product.item_name}</td>
                                <td>{product.model_name}</td>
                                <td>{product.maker_name}</td>
                                <td>{product.scenario_type}</td>
                                <td>{product.qt_no}</td>
                                <td>{product.qt_type}</td>
                                <td>{formatDateTime(product.qt_end_time)}</td>
                                <td>{product.qt_seq}</td>
                                <td>{product.qt_id}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="action-buttons">
                <button className="primary-button" onClick={handleNegotiate}>협상 참여</button>
                <button className="secondary-button">미참여</button>
            </div>
        </div>
    );
};

export default QuotationList;