// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.negotiation-summary {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.summary-header {
    background-color: #00b8bf;
    color: white;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px;
    text-align: center;
}

.summary-intro {
    margin-top: 20px;
    line-height: 1.6;
}

.summary-list {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

.summary-list li {
    margin-bottom: 10px;
}

.product-table {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-row {
    display: contents;
}

.summary-footer {
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
    color: #333;
}`, "",{"version":3,"sources":["webpack://./src/css/NegoSummary.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,iBAAiB;IACjB,wCAAwC;AAC5C;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sCAAsC;IACtC,SAAS;IACT,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".negotiation-summary {\n    background-color: #f8f9fa;\n    border-radius: 8px;\n    padding: 20px;\n    width: 90%;\n    margin: 20px auto;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.summary-header {\n    background-color: #00b8bf;\n    color: white;\n    padding: 10px;\n    font-size: 18px;\n    font-weight: bold;\n    border-radius: 8px;\n    text-align: center;\n}\n\n.summary-intro {\n    margin-top: 20px;\n    line-height: 1.6;\n}\n\n.summary-list {\n    list-style-type: none;\n    padding: 0;\n    margin-top: 10px;\n}\n\n.summary-list li {\n    margin-bottom: 10px;\n}\n\n.product-table {\n    margin-top: 20px;\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    gap: 10px;\n    background-color: #ffffff;\n    padding: 15px;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.table-row {\n    display: contents;\n}\n\n.summary-footer {\n    margin-top: 20px;\n    font-weight: bold;\n    text-align: center;\n    color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
