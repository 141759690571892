import React, { useRef, useState, useEffect } from 'react';
import '../css/Information.css';

const Information = ({ onClose, isFirstOpen, showTitle = true }) => {
    const [isChecked, setIsChecked] = useState(false); // 체크박스 상태
    const [selectedOption, setSelectedOption] = useState('');
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
    };

    useEffect(() => {
        if (!isFirstOpen) {
            setSelectedOption('agree'); // 첫 열림이 아닐 때 '동의함' 선택 상태로 설정
        } else {
            setSelectedOption(''); // 첫 열림일 경우 아무것도 선택되지 않은 상태로 유지
        }
    }, [isFirstOpen]);

    useEffect(() => {
        setIsButtonEnabled(selectedOption === 'agree');
    }, [selectedOption]);

    return (
        <div className="popup-overlay">
            <div className="popup">
                <div className="popup-content">
                    {showTitle && <h1 style={{ textAlign: 'center' }}>협상 유의사항 및 서비스 이용 안내</h1>}
                    <p className="popup-sub">
                        협상과 관련하여 다음을 안내드리오니<br/>
                        반드시 참고하시어 입찰에 불이익이 없도록<br/>
                        유의하여 참여해 주시기 바랍니다.
                    </p>
                    {/* <h3>[필수] 이용약관 동의</h3> */}
                    <div className='notice-content'>
                        <ul>
                            <li className='notice-list'>
                                협상 개시는 입찰 이메일을 확인하는 순간부터 진행됩니다.<br/>
                                협상 마감은 개시 후 12시간입니다. 시간 준수를 부탁 드립니다.<br/>
                                협상 시한을 경과하는 경우 1회에 한하여 추가 3시간 연장을 부여하며, 연장에 불구 응찰하지 않는 경우 입찰 참여의사가 없는 것으로 간주하여 다른 협상자에게 기회가 넘어갈수 있으니, 불이익이 없도록 유의 바랍니다.
                            </li>
                            <li className='notice-list'>
                                협상은 반드시 지정된 담당자가 진행 부탁드리며, 승인되지 않은 자가 협상을 진행하여 발생하는 불이익에 대해서는 KT커머스는 책임지지 않습니다.<br/>
                                협상의 권한을 제 3자에게 양도하거나 위임할 수 없으며, 협상 링크와 함께 전달되는 로그인 암호는 반드시 담당자만 확인하고 외부에 유출되지 않도록 관리 바랍니다.
                            </li>
                            <li className='notice-list'>
                                협상에 입력되는 모든 가격은 부가세 별도 및 배송비 제외 기준으로 적용되며,<br/>
                                할인율을 요청하는 경우 기존 공급가격을 기준으로 환산되어 제시되는 금액은 십원미만 절상으로 반영되어 처리되니 참고하시기 바랍니다.
                            </li>
                            <li className='notice-list'>
                                본 협상 결과에 대해서는 협상자와 협상대상자 간의 비밀 유지 조건으로 진행되고,<br/>
                                협상에서 얻어진 결과나 내용에 대해서는 당사자를 제외하고 제 3자에 공유할 수 없으며, 비밀 유지를 전제로 진행됩니다.
                            </li>
                            <li className='notice-list'>
                                협상이 종결되면 특별한 사유 없이 취소 변경이 불가하오니 신중한 의사 결정을 부탁 드리며, KT커머스가 부여한 협상 기회를 이유 없이 거부하거나 응답하지 않아 협상 개시가 진행되지 않고 종결되는 경우 향후 협력사 평가에서 불이익이 발생할 수 있으니 유념하여 주시기 바랍니다.
                            </li>
                        </ul>
                        <div className='popup-sub-section'>
                            <p className="popup-sub">
                                기타 협상과정에서 궁금한 사항이나 문의하실 사항은<br/>
                                아래의 연락처로 상담을 부탁드립니다.
                            </p>

                            <p className="popup-sub">
                                [헬프데스크 1588-1234, 이메일: help@ktcommerce.com]
                            </p>
                        </div>
                    </div>

                    {/* 팝업이 처음 열렸을 때만 팝업을 닫기위해 약관 동의 체크버튼 표시 */}
                    {/* {isFirstOpen && (
                        <>
                            <div className="agreement-choice">
                                <span style={{margin: '10px 0 10px 0'}}>협상 유의사항 및 서비스 이용 안내를 읽고 동의합니다.</span>
                                <div className="radio-group">
                                    <label>
                                        <input 
                                            type="radio" 
                                            name="agreement" 
                                            value="disagree" 
                                            checked={selectedOption === 'disagree'}
                                            onChange={handleRadioChange}
                                        />
                                        <span className="agreement-text">동의하지 않음</span>
                                    </label>
                                    <label>
                                        <input 
                                            type="radio" 
                                            name="agreement" 
                                            value="agree" 
                                            checked={selectedOption === 'agree'}
                                            onChange={handleRadioChange}
                                        />
                                        <span className="agreement-text">동의함</span>
                                    </label>
                                </div>
                            </div>
                        </>
                    )} */}
                </div>
                {/* <button
                    className="popup-button"
                    onClick={onClose}
                    disabled={!isButtonEnabled}
                >
                    {isButtonEnabled
                        ? isFirstOpen
                            ? '귀사는 약관에 동의하셨으며, 지금부터 협상을 시작하겠습니다.'
                            : '확인'
                        : '이용약관 동의가 필요합니다.'}
                </button> */}
                <button
                    className="popup-button"
                    onClick={onClose}
                >
                    확인
                </button>
            </div>
        </div>
    );
};

export default Information;
